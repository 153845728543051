import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "bar"];

  connect() {
    console.log("Progress controller connected!");

    // Listen for Turbo Stream updates
    this.element.addEventListener("turbo:stream-action", this.handleTurboStreamUpdate.bind(this));

  }

  handleTurboStreamUpdate(event) {
    const { current, total } = event.detail;

    // Update the progress bar
    if (this.hasBarTarget) {
      const percentage = (current / total) * 100;
      this.barTarget.style.width = `${percentage}%`;
    }

    // Update the progress message
    this.messageTarget.textContent = `Progress: ${current} of ${total}`;
  }
}
